import React from "react"
import { StaticQuery, graphql } from "gatsby"
import * as am5 from "@amcharts/amcharts5/"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import * as styles from "../../../styles/components/chart.module.scss"
import canvasMemoryReset from "../../../utils/canvas-memory-reset"
import specialCharacterReplace from "../../../utils/special-character-replace"
import nl2br from "react-nl2br"

// markup
class BarBasic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData
    }
    
    this.barColor = "#4472C4"
    this.filePath = `/csv/bar-basic/` // CSVデータ格納先
  }

  // config setter
  setConfig(data) {
    this.config = data
  }

  // config getter
  getConfig() {
    return this.config
  }

  // チャートの設定
  async setChartData() {
    am5.addLicense(process.env.AM_CHARTS_LICENSE)
    
    const chartConfig = this.getConfig()

    let root = am5.Root.new(this.props.id)
    root.setThemes([
      am5themes_Animated.new(root)
    ])
    this.root = root

    // 基本設定
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      layout: root.verticalLayout
    }));
    chart.children.unshift(am5.Label.new(root, {
      text: chartConfig.title[this.state.chartData],
      fontSize: "12px",
      centerX: am5.percent(50),
      x: am5.percent(50)
    }))
    
    // CSVファイルからデータの読み込み
    let dataSource = `${this.filePath}${this.state.chartData}.csv`
    let data = await am5.net.load(dataSource).then(function(result) {
      // CSVパースオプション
      let data = am5.CSVParser.parse(result.response, {
        delimiter: ",",
        reverse: false,
        skipEmpty: true,
        useColumnNames: true
      });
      // 型変換処理
      let processor = am5.DataProcessor.new(root, {
        numericFields: ['value']
      });
      processor.processMany(data);

      return data
    })

    // XY座標の設定
    // X座標
    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    let xRendererOptions = {}
    if (chartConfig.option[this.state.chartData].xAxisRotate < 0) {
      xRendererOptions = {
        rotation: chartConfig.option[this.state.chartData].xAxisRotate,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 15,
        fontSize: "11px"
      }
    } else {
      xRendererOptions = {
        rotation: chartConfig.option[this.state.chartData].xAxisRotate,
        fontSize: "11px"
      }
    }
    xRenderer.labels.template.setAll(xRendererOptions);

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: chartConfig.option[this.state.chartData].category,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );
    xAxis.data.setAll(data);
    this.xAxis = xAxis

    // Y座標(左)
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: chartConfig.axis[this.state.chartData][1].min,
        max: chartConfig.axis[this.state.chartData][1].max,
        numberFormat: "#,###",
        strictMinMax: true,
        calculateTotals: true,
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );
    let rangeDataItem = yAxis.makeDataItem({
      value: chartConfig.axis[this.state.chartData][1].range
    })
    yAxis.createAxisRange(rangeDataItem)
    let yRenderer = yAxis.get('renderer')
    yRenderer.labels.template.setAll({
      fontSize: "11px"
    })
    yAxis.children.unshift(am5.Label.new(root, {
      rotation: -90,
      text: chartConfig.axis[this.state.chartData][1].title,
      y: am5.p50,
      centerX: am5.p50,
      fontSize: "11px",
    }))    
    this.yAxis = yAxis
  
    // シリーズの設定
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Series1",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      sequencedInterpolation: true,
      categoryXField: chartConfig.option[this.state.chartData].category,
      tooltip: am5.Tooltip.new(root, {
        labelText: chartConfig.axis[this.state.chartData][1].tooltip
      })
    }));
    series.columns.template.setAll({
      tooltipText: chartConfig.axis[this.state.chartData][1].tooltip,
      fill: this.barColor,
      stroke: this.barColor,
      templateField: "columnSettings"
    });
    
    series.columns.template.setAll({ cornerRadiusTL: 0, cornerRadiusTR: 0 });
    series.columns.template.adapters.add("fill", (fill, target) => {
      return this.barColor;
    });
    
    series.columns.template.adapters.add("stroke", (stroke, target) => {
      return this.barColor;
    });

    xAxis.data.setAll(data);
    series.data.setAll(data);
    series.appear();

    this.chart = chart
  }

  componentDidMount() {
    this.setChartData()
  }

  componentDidUpdate(prevState) {
    if (prevState.dataSourceUrl !== this.state.dataSourceUrl ) {
      this.root.dispose()
      this.setChartData()
    }
  }

  componentWillUnmount() {
    canvasMemoryReset(`#${this.props.id} canvas`)
    if (this.root) {
      this.root.dispose()
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query allBarBasicQuery {
            settings: allBarBasicJson {
              edges {
                node {
                  option {
                    amountShip {
                      category
                      xAxisRotate
                    }
                    co2SeparateEmission {
                      category
                      xAxisRotate
                    }
                  }
                  axis {
                    amountShip {
                      label
                      max
                      min
                      range
                      title
                      type
                      tooltip
                    }
                    co2SeparateEmission {
                      label
                      max
                      min
                      range
                      title
                      type
                      tooltip
                    }
                  }
                  head {
                    amountShip
                    co2SeparateEmission
                  }
                  title {
                    amountShip
                    co2SeparateEmission
                  }
                  source {
                    amountShip {
                      sourceText
                      sourceDate
                      sourceLink
                      explain  
                    }
                    co2SeparateEmission {
                      sourceText
                      sourceDate
                      sourceLink
                      explain  
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            { this.setConfig(data.settings.edges[0].node) }
            <div className={styles.chartTitle}>
              <h2 dangerouslySetInnerHTML={{ __html: specialCharacterReplace(data.settings.edges[0].node.head[this.state.chartData]) }} />
            </div>
            <div id={this.props.id} style={{ width: this.props.width, height: this.props.height }}></div>
            <div className={styles.chartNote}>
              <p className={styles.chartSource}>出所）<a href={data.settings.edges[0].node.source[this.state.chartData].sourceLink} target="_blank" rel="noreferrer noopener">{data.settings.edges[0].node.source[this.state.chartData].sourceText}</a>
              {data.settings.edges[0].node.source[this.state.chartData].sourceDate}
              </p>
              <p className={styles.chartExplain} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(data.settings.edges[0].node.source[this.state.chartData].explain) }} />
              <p><a className={styles.chartDataDownload} href={`${this.filePath}${this.state.chartData}.csv`}>データダウンロード</a></p>
            </div>
          </>
        )}
      />
    )
  }
}
export default BarBasic
