import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"
import specialCharacterReplace from "../../utils/special-character-replace"

import SearchFilter from "../../components/parts/chart/search-filter"
import BarBasic from "../../components/parts/chart/bar-basic"
import BarGroupedLegend from "../../components/parts/chart/bar-grouped-legend"
import PieLegend from "../../components/parts/chart/pie-legend"
import BarStackedHorizontal from "../../components/parts/chart/bar-stacked-horizontal"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s07_1_1_1 : false,
      s07_1_1_2 : false,
      s07_1_1_4 : false,
      s07_1_1_5 : false,
      s07_1_1_7 : false
    }

    this.searchInit = {
      filter1Init: 4,
      filter2Init: 3,
      filter3Init: 'market-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <BarBasic id="co2SeparateEmission" chartData="co2SeparateEmission" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <BarGroupedLegend id="co2SeparateCollect" chartData="co2SeparateCollect" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <PieLegend id="co2SeparateCollectFacilityStatus" chartData="co2SeparateCollectFacilityStatus" width="100%" height="420px" />
              <PieLegend id="co2SeparateCollectFacilityStorage" chartData="co2SeparateCollectFacilityStorage" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <BarStackedHorizontal id="co2SeparateCollectFacilityArea" chartData="co2SeparateCollectFacilityArea" width="100%" height="420px" />
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`