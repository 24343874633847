import React from "react"
import { StaticQuery, graphql } from "gatsby"
import * as am5 from "@amcharts/amcharts5/"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import * as styles from "../../../styles/components/chart.module.scss"
import nl2br from "react-nl2br"
import specialCharacterReplace from "../../../utils/special-character-replace"
import canvasMemoryReset from "../../../utils/canvas-memory-reset"

// markup
class BarStackedHorizontal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData
    }

    this.filePath = "/csv/bar-stacked-horizontal/" // CSVデータ格納先
  }

  // config setter
  setConfig(data) {
    this.config = data
  }

  // config getter
  getConfig() {
    return this.config
  }

  // チャートの設定
  async setChartData() {
    am5.addLicense(process.env.AM_CHARTS_LICENSE)
    
    let chartConfig = this.getConfig()
    let root = am5.Root.new(this.props.id)
    let xLabel = chartConfig.axis[this.state.chartData][0].title
    //console.log(xLabel)
    root.setThemes([
      am5themes_Animated.new(root)
    ])
    this.root = root

    // 基本設定
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      layout: root.verticalLayout
    }))
    chart.children.unshift(am5.Label.new(root, {
      text: chartConfig.title[this.state.chartData],
      fontSize: "12px",
      centerX: am5.percent(50),
      x: am5.percent(50)
    }))

    // Y座標設定
    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: xLabel,
      dx: 28,
      renderer: am5xy.AxisRendererY.new(root, {
        minGridDistance: 20,
        inversed: true,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9
      }),
      tooltip: am5.Tooltip.new(root, {})
    }))
    let yRenderer = yAxis.get('renderer')
    yRenderer.labels.template.setAll({
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      fontSize: "11px"
    })

    // CSVファイルからデータの読み込み
    let fields = chartConfig.series[this.state.chartData].map((item) => { return item.name})
    let dataSource = `${this.filePath}${this.state.chartData}.csv`
    let data = await am5.net.load(dataSource).then(function(result) {
      // CSVパースオプション
      let data = am5.CSVParser.parse(result.response, {
        delimiter: ",",
        reverse: false,
        skipEmpty: true,
        useColumnNames: true
      });
      // 型変換処理
      let processor = am5.DataProcessor.new(root, {
        numericFields: fields
      });
      processor.processMany(data);
      yAxis.data.setAll(data);      
      return data
    })
    // X座標
    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {
        strokeOpacity: 0.1
      }),
      min: 0
    }));
    let rangeDataItem = xAxis.makeDataItem({
      value: chartConfig.axis[this.state.chartData][0].range
    })
    xAxis.createAxisRange(rangeDataItem)

    let xRenderer = xAxis.get('renderer')
    xRenderer.labels.template.setAll({
      fontSize: "11px"
    })

    xAxis.children.unshift(am5.Label.new(root, {
      y: am5.p50,
      centerX: am5.p50,
      fontSize: "11px",
    }))

    this.xAxis = xAxis
    
    this.chart = chart

    this.yAxis = yAxis

    // Legendの指定
    let legend = chart.children.push(am5.Legend.new(root, {
      x: am5.percent(0),
      centerX: am5.percent(0),
      width: am5.percent(100),
      layout: root.gridLayout,
      clickTarget: "none"
    }))

    legend.labels.template.setAll({
      fontSize: 11
    })
    legend.markers.template.setAll({
      width: 14,
      height: 14
    })
    // Create series プルダウンが「風力」の時は風力のみを作成
    chartConfig.series[this.state.chartData].forEach(item => {
      this.createSeries(data, legend, item.name, item.fill)
    });
    
  }

  componentDidMount() {
    this.setChartData()
  }

  componentWillUnmount() {
    canvasMemoryReset(`#${this.props.id} canvas`)
    if (this.root) {
      this.root.dispose()
    }
  }

  createSeries(data, legend, name, fill) {  
    // シリーズの設定
    let root = this.root
    let chart = this.chart
    let xAxis = this.xAxis
    let yAxis = this.yAxis
    let xLabel = this.getConfig().axis[this.state.chartData][0].title

    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: name,
      stacked: true,
      yAxis: yAxis,
      xAxis: xAxis,
      valueXField: name,
      categoryYField: xLabel,
      sequencedInterpolation: "horizontal",
      labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"
    }))
    
    // ツールチップ設定
    series.columns.template.setAll({
      tooltipText: (this.getConfig().download[this.state.chartData]) ? `[bold]{name}[/]: {valueX}` : ``,
      tooltipY: am5.percent(10),
      fill: fill,
      stroke: fill
    })
    series.data.setAll(data);
    
    series.appear();

    legend.data.push(series);
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query barStackedHorizontalQuery {
            settings: allBarStackedHorizontalJson {
              edges {
                node {
                  axis {
                    co2SeparateCollectFacilityArea {
                      label
                      max
                      min
                      range
                      title
                      type
                    }
                  }
                  series {
                    co2SeparateCollectFacilityArea {
                      fill
                      label
                      name
                      type
                    }
                  }
                  head {
                    co2SeparateCollectFacilityArea
                  }
                  title {
                    co2SeparateCollectFacilityArea
                  }
                  download {
                    co2SeparateCollectFacilityArea
                  }
                  source {
                    co2SeparateCollectFacilityArea {
                      explain
                      sourceDate
                      sourceLink
                      sourceText
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            { this.setConfig(data.settings.edges[0].node) }
            <div className={styles.chartTitle}>
              <h2 dangerouslySetInnerHTML={{ __html: specialCharacterReplace(data.settings.edges[0].node.head[this.state.chartData]) }} />
            </div>
            <div id={this.props.id} style={{ width: this.props.width, height: this.props.height }}></div>
            <div className={styles.chartNote}>
              <p className={styles.chartSource}>出所）<a href={data.settings.edges[0].node.source[this.state.chartData].sourceLink} target="_blank" rel="noreferrer noopener">{data.settings.edges[0].node.source[this.state.chartData].sourceText}</a>
              {data.settings.edges[0].node.source[this.state.chartData].sourceDate}
              </p>
              <p className={styles.chartExplain} dangerouslySetInnerHTML={{ __html: nl2br(specialCharacterReplace(data.settings.edges[0].node.source[this.state.chartData].explain)) }} />
              { (data.settings.edges[0].node.download[this.state.chartData]) ? 
                <p><a className={styles.chartDataDownload} href={`${this.filePath}${this.state.chartData}.csv`}>データダウンロード</a></p>
                : ''
              }
            </div>
          </>
        )}
      />
    )
  }
}
export default BarStackedHorizontal
